import { makeStyles } from '@material-ui/core'
import React from 'react'
import Button from '../../../shared/components/atoms/Button/Button'
import SelectOutlinedMaterial from '../../../shared/components/atoms/select-outlined-material/select-outlined-material'
import { MONTHS_SHORTNAME } from '../../../shared/state/constants/appConstants'
import { formatNumber } from '../../../shared/state/utils/utilities-format-number'
import './mypointsTable.scss'
import { convertText } from '../../../shared/utils/convertFirstCharacterToUppercase'

const MypointsTable = ({ points }) => {
	const [filteredDates] = React.useState(getCreationDates())
	const [activeTag, setActiveTag] = React.useState('Todas')
	const [numItemsToShow, setNumItemsToShow] = React.useState(12)
	const [filteredPoints, setFilteredPoints] = React.useState(
		points.slice(0, numItemsToShow)
	)

	function getCreationDates() {
		const datesArray = ['Todas']
		points.forEach(point => {
			const date = new Date(point.creationDate)
			const year = date.getFullYear().toString()
			if (!datesArray.includes(year)) {
				return datesArray.push(year)
			}
		})
		return datesArray
	}

	const useStyles = makeStyles(() => ({
		formControl: {
			margin: 0,
			minWidth: '170px',
		},
		label: {
			color: '#7996A8',
			fontSize: '1rem',
			marginLeft: 16,
			backgroundColor: 'white',
		},
		select: {
			color: '#7996A8',
			border: 'none',
		},
	}))
	const classes = useStyles()

	const handleLoadMore = () => {
		let newItemsToShow = numItemsToShow
		newItemsToShow += numItemsToShow
		setNumItemsToShow(newItemsToShow)
		setFilteredPoints(points.slice(0, newItemsToShow))
	}

	const formatDateForMyOrders = _date => {
		const date = new Date(_date)
		return `${date.getDate()} / ${
			MONTHS_SHORTNAME[date.getMonth()]
		} / ${date.getFullYear()}`
	}

	const handleFilterYears = _date => {
		const date = new Date(_date)
		return date.getFullYear().toString()
	}

	const handleChangeTag = _value => {
		setActiveTag(_value)
		if (_value.toLowerCase() === 'todas') {
			setFilteredPoints(points)
		} else {
			const newPoints = points.filter(
				point => handleFilterYears(point.creationDate) === _value
			)
			setFilteredPoints(newPoints)
		}
	}

	return (
		<div className='f-section-table-container'>
			<div className='f-filter-container'>
				<label className='f-p f-filter-title'>
					Filtra tus puntos
					<br />
				</label>
				<SelectOutlinedMaterial
					value={activeTag}
					setValue={handleChangeTag}
					styles={classes.formControl}
					styleLabel={classes.label}
					styleSelect={classes.select}
					name={'pointsTags'}
					label=''
					items={(filteredDates || []).map(date => ({
						key: date,
						value: date,
					}))}
				/>
			</div>

			<div className='f-header-table-container'>
				<div className='f-item-header-container item1'>
					<label className='f-item-title'>Fecha de ingreso</label>
				</div>
				<div className='f-item-header-container item2'>
					<label className='f-item-title'>Nombre del producto</label>
				</div>
				<div className='f-item-header-container item3'>
					<label className='f-item-title'>Código del producto</label>
				</div>
				<div className='f-item-header-container item4'>
					<label className='f-item-title'>Puntos</label>
				</div>
				<div className='f-item-header-container item5'>
					<label className='f-item-title'>Fecha de vencimiento</label>
				</div>
			</div>
			<div className='f-table-container'>
				{filteredPoints.map((item, index) => {
					return (
						<div
							className={`${
								item.expired
									? 'f-table-row f-table-row--disabled'
									: 'f-table-row'
							}`}
							key={index}
						>
							<div className='f-item-container item1'>
								<label className='f-item-title-mobile'>Fecha de ingreso</label>
								<label className='f-item'>
									{formatDateForMyOrders(item.creationDate)}
								</label>
							</div>
							<div className='f-item-container item2'>
								<label className='f-item-title-mobile'>
									Nombre del producto
								</label>
								<label className='f-item'>
									<span>
										<img src={item.image} alt='Imagen del producto' />
									</span>
									<p>{item.typePoint}</p>
								</label>
							</div>
							<div className='f-item-container item3'>
								<label className='f-item-title-mobile'>
									Código del producto
								</label>
								<label className='f-item'>{item.key}</label>
							</div>
							<div className='f-item-container item4'>
								<label className='f-item-title-mobile'>Puntos</label>
								<label className='f-item'>{formatNumber(item.points)}</label>
							</div>
							<div className='f-item-container item5'>
								<label className='f-item-title-mobile'>
									Fecha de vencimiento
								</label>
								<label className='f-item'>
									{formatDateForMyOrders(item.expirationDate)}
								</label>
							</div>
						</div>
					)
				})}
				{filteredPoints.length === 0 ? (
					<p className='f-p f-nopoints'>No se encontraron resultados.</p>
				) : (
					<></>
				)}
			</div>
			<div className='f-button-loading-content'>
				{numItemsToShow >= points.length ? (
					<></>
				) : (
					<Button
						text={convertText('CARGAR MÁS DESCUENTOS')}
						type='button'
						classname='f-button f-button--primary'
						onClickAction={() => {
							handleLoadMore()
						}}
					/>
				)}
			</div>
		</div>
	)
}

export default MypointsTable
