import React from 'react'
import ProfileService from '../../../shared/services/profile'
import { successStatus } from '../../../shared/state/constants/appConstants'
import MypointsTable from '../mypointsTable/mypointsTable'
import './mypointsSection.scss'
import AdviserService from '../../../shared/services/adviser'
import { isAdviser } from '../../../shared/state/utils/isAdviser'
import SessionService from '../../../shared/services/session'

const MypointsSection = () => {
	const [points, setPoints] = React.useState()

	React.useEffect(() => {
		if (isAdviser(SessionService.getIdTokenData())) {
			AdviserService.myPoints(
				restData => {
					const { data } = restData
					if (data.status === successStatus) {
						setPoints(data.data)
					}
				},
				error => {
					console.log(error)
				}
			)
		} else {
			ProfileService.myPoints(
				restData => {
					const { data } = restData
					if (data.status === successStatus) {
						setPoints(data.data)
					}
				},
				error => {
					console.log(error)
				}
			)
		}
	}, [])

	return (
		<section className='f-mypoints-section'>
			<div className='f-mypoints-header-section'>
				<div className='f-mypoint-title-container'>
					<h1 className='f-mypoint-title'>MIS PUNTOS</h1>
					<label className='f-mypoint-subtitle'>
						Este es el resumen de tus puntos obtenidos por los empaques y
						regalos.
					</label>
				</div>
			</div>
			{points && <MypointsTable points={points} />}
		</section>
	)
}

export default MypointsSection
